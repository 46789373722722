import { createSlice } from "@reduxjs/toolkit";
// import { getLocalStorageItem } from "../Utils";

const initialState = {};

export const b2bSlice = createSlice({
  name: "E2C",
  initialState,
  reducers: {
    setHeadings: (state, action) => {
      state.heading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHeadings } = b2bSlice.actions;

export default b2bSlice.reducer;
