import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundry";
import { getLocalStorageItem } from "../Utils";
import ProjectLayout from "../ProjectLayout";
import { Spin } from "antd";

function ProtectedAuth() {
  const isAuthenticated = () => {
    const user = getLocalStorageItem("accessToken");
    return user;
  };


  return !isAuthenticated() ? (
    <>
      {" "}
      <ProjectLayout>
        <ErrorBoundary>
        <Suspense
              fallback={
                <div className="flex justify-center items-center h-screen">
                  <Spin tip={<p>Loading....</p>} spinning={true}></Spin>
                </div>
              }
            >
          <Outlet />
          </Suspense>
        </ErrorBoundary>
      </ProjectLayout>
    </>
  ) : (
    <>
      <Navigate to="/" replace />
    </>
  );
}
export default ProtectedAuth;
