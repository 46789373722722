import { lazy } from "react";
import ProtectedAuth from "./PrivateAuth";
const Dashboard = lazy(() => import("../Views/Main/Dashboard/Dashboard"));
const Courses = lazy(() => import("../Views/Main/Course/Courses"));
const Cart = lazy(() => import("../Views/Main/Cart/Cart"));
const Checkout = lazy(() => import("../Views/Main/Checkout/Checkout"));
const OrderSuccess = lazy(() => import("../Views/Main/OrderSuccess/OrderSuccess"));

export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/Dashboard",
          element: <Dashboard />,
        },
        {
          path: "/Courses",
          element: <Courses />,
        },
        {
          path: "/Cart",
          element: <Cart />,
        },
        {
          path: "/Checkout",
          element: <Checkout />,
        },
        {
          path: "/Order-success",
          element: <OrderSuccess />,
        },
        // ERROR ROUTES ENDED
      ],
    },
  ];
};
