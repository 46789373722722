import PublicAuth from "./PublicAuth";
import Login from "../Views/Auth/Login";
import SignUp from "../Views/Auth/SignUp";

export const PublicRoutes = () => {
  return [
    {
      element: <PublicAuth />,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "/signup",
          element: <SignUp />,
        },
      ],
    },
  ];
};
