import { CiCalendar, CiSettings } from "react-icons/ci";
import { FaAddressBook } from "react-icons/fa";
import { GoCommentDiscussion } from "react-icons/go";
import { MdDashboard, MdOutlineAccountBox } from "react-icons/md";
import { TiBookmark } from "react-icons/ti";
import { NavLink } from "react-router-dom";

export const MenuItems=()=>{
    const list = [
        {
          key: "1",
          icon: <MdDashboard />,
          label: (
              <NavLink to={"/dashboard"}>
                <p>Dashboard</p>
              </NavLink>
          ),
         
        },
        {
            key: "2",
            icon: <FaAddressBook />,
            label: (
               <NavLink to={"/Courses"}>
                 <p>Courses</p>
               </NavLink>
            ),
           
          }, {
            key: "3",
            icon: <TiBookmark />,
            label: (
                <p>My Coureses</p>
            ),
           
          }, {
            key: "4",
            icon: <GoCommentDiscussion />,
            label: (
                <p>Discussion</p>
            ),
           
          }, {
            key: "5",
            icon: <CiCalendar />,
            label: (
                <p>Schedule</p>
            ),
           
          }, {
            key: "6",
            icon: <MdOutlineAccountBox />,
            label: (
                <p>My Account</p>
            ),
           
          }, {
            key: "7",
            icon: <CiSettings />,
            label: (
                <p>Setting</p>
            ),
           
          },
    ]
    return list
}