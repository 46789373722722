import {  Post, getAPIUrl } from "../../ApiServices/apiMethod";
import { Authendpoint } from "./AuthEndpoint";

export const userLogin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Login, param);
  return Post(url, data, false);
};

export const userRegister = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.SignUp, param);
  return Post(url, data, false);
};
export const ForgotPassoword = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.forgotPassword, param);
  return Post(url, data, false);
};
